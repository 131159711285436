import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { actions } from "react-redux-form";
import "./style.css";

class AlertMessage extends Component {
  state = {
    showAlert: true,
  };
  render() {
    // const { alertText, error, dispatch } = this.props;
    const { userActions, signup, sendEmail, dispatch } = this.props;
    const { showAlert } = this.state;

    const alertText =
      userActions?.message ||
      userActions?.error ||
      signup?.message ||
      signup?.error ||
      sendEmail?.message ||
      sendEmail?.error;

    const error = userActions?.error || signup?.error || sendEmail?.error;

    if (alertText && showAlert) {
      return (
        <Alert
          variant={error ? "danger" : "success"}
          className="alertDiv ivyPrestoRegular"
          onClose={() => {
            this.setState({ showAlert: false });
            dispatch(actions.change("user.error", null));
          }}
          dismissible
        >
          <p className="alertText">{alertText}</p>
        </Alert>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  signup: state.signup,
  userActions: state.userActions,
  sendEmail: state.sendEmail,
});

export default connect(mapStateToProps)(AlertMessage);
