import {
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  TOKEN_VERIFY,
  TOKEN_VERIFIED,
  TOKEN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SUBMIT_DROPBOX,
  SUBMIT_DROPBOX_SUCCESS,
  SUBMIT_DROPBOX_ERROR,
} from "./constants";
import { combineReducers } from "redux";
import {
  createForms, // optional
} from "react-redux-form";

const initialState = {
  isLoading: false,
  files: [],
};

const userInitialState = {
  ...this,
};

export const checkToken = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case CHECK_TOKEN:
      return {
        isLoading: true,
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        isLoading: false,
        tokenExpired: false,
      };
    case CHECK_TOKEN_ERROR:
      return {
        isLoading: false,
        tokenExpired: true,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const options = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_OPTIONS:
      return {
        isLoading: true,
      };
    case GET_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        data: payload.data,
      };
    case GET_OPTIONS_ERROR:
      return {
        isLoading: false,
        data: null,
      };
    default:
      return state;
  }
};

export const signup = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
      return { signedUp: true, message: payload.message };
    case SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        login: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const user = (state = {}, { type, payload = "" }) => {
  switch (type) {
    // case SIGNUP:
    case LOGIN:
    case REFRESH_USER:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        id: payload.id,
        login: true,
        full_name: payload.full_name,
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        token: payload.token,
        userInfo: payload.userInfo,
      };
    case REFRESH_USER_SUCCESS:
    case REFRESH_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        userInfo: payload.userInfo,
      };
    // case SIGNUP_ERROR:
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        login: false,
        error: payload.message,
        loginError: true,
      };
    case LOGOUT:
      return {
        isLoading: false,
        login: false,
      };
    // case SUBMIT_DROPBOX_SUCCESS:
    //   return {
    //     state: null,
    //   };
    default:
      return state;
  }
};

export const userActions = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        password: null,
        confirm_password: null,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
        signupTried: true,
        error: payload.message,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        loginTried: true,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const sendEmail = (state = userInitialState, { type, payload = "" }) => {
  switch (type) {
    case FORGOT_PASSWORD:
    case TOKEN_VERIFY:
      return {
        isLoading: true,
        ...state,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        sent_token: true,
        message: payload.message,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        sent_token: false,
        error: payload.message,
      };
    case TOKEN_ERROR:
      return {
        token_success: false,
        error: payload.message,
      };
    case TOKEN_VERIFIED:
      return {
        token_success: true,
        message: payload.message,
        email: payload.email,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        reset_success: true,
        message: payload.message,
      };
    case RESET_PASSWORD_ERROR:
      return {
        reset_success: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export const userForm = (state = initialState, { type, payload = "" }) => {
  switch (type) {
    case SUBMIT_DROPBOX:
      return {
        ...state,
        isLoading: true,
      };
    case SUBMIT_DROPBOX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case SUBMIT_DROPBOX_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case REFRESH_USER_SUCCESS:
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload.userInfo,
      };
    default:
      return state;
  }
};

export default combineReducers({
  ...createForms({
    options,
    user,
    signup,
    userActions,
    userForm,
    sendEmail,
  }),
});
