import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "./img/logo.png";
import "./style.css";
import "../../css/globalStyle.css";
import { Button } from "react-bootstrap";

export default class Thankyou extends Component {
  render() {
    return (
      <div className="formMain">
        <div className="formDiv">
          <img src={logo} className="logo" alt="Logo" />
          <h4 className="header">
            Thank you! Your information has successfully been submitted. We will
            reach out to you as soon as we finish reviewing your information.
          </h4>
          <p style={{ textAlign: "center" }}>
            <b>
              If you have any additional information to provide, please call
              (800) 713-7959
            </b>
          </p>
          <div style={{ textAlign: "center" }}>
            <Button href="/" className="submitButton hoverDelay" type="submit">
              Return Home
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
