import {
  put,
  takeLatest,
  takeLeading,
  all,
  call,
  select,
} from "redux-saga/effects";
import {
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  SUBMIT_DROPBOX,
  SUBMIT_DROPBOX_SUCCESS,
  SUBMIT_DROPBOX_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  TOKEN_VERIFY,
  TOKEN_VERIFIED,
  TOKEN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./constants";
import { request } from "../components/utils/utils.js";
// import { saveAs } from "file-saver";
import {
  formatDate,
  createDropboxFolder,
  uploadDropboxFile,
} from "./dropboxAPI";
import * as selectors from "./selectors";

function* checkToken() {
  const user = yield select(selectors.user);
  let result;
  try {
    result = yield call(request, "/checkToken", {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
    });
    if (result.data.message) {
      yield put({
        type: CHECK_TOKEN_SUCCESS,
        payload: { message: result.data.message },
      });
    } else {
      yield put({
        type: CHECK_TOKEN_ERROR,
        payload: { error: result.data.error },
      });
      yield put({ type: "LOGOUT" });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CHECK_TOKEN_ERROR,
      payload: { error: result.data.error },
    });
  }
}

function* getOptions() {
  let result;
  try {
    result = yield call(request, "/options", {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (result?.data?.message) {
      yield put({
        type: GET_OPTIONS_SUCCESS,
        payload: { data: result?.data?.options },
      });
    } else {
      yield put({
        type: GET_OPTIONS_ERROR,
        payload: { error: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_OPTIONS_ERROR,
      payload: { error: result?.data?.error },
    });
  }
}

function* refreshUser() {
  const user = yield select(selectors.user);
  let result;
  try {
    result = yield call(request, "/refreshUser", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ user }),
    });
    if (result?.data?.message) {
      yield put({
        type: REFRESH_USER_SUCCESS,
        payload: { userInfo: result?.data?.userInfo },
      });
    } else {
      yield put({
        type: REFRESH_USER_ERROR,
        payload: { error: result.data.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: REFRESH_USER_ERROR,
      payload: { error: result.data.error },
    });
  }
}

function* signup() {
  const userActions = yield select(selectors.userActions);
  let data;
  try {
    const result = yield call(request, "/signup", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userActions),
    });
    ({ data } = result);
    if (data?.message) {
      yield put({
        type: SIGNUP_SUCCESS,
        payload: {
          id: data.id,
          full_name: data.full_name,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          token: data.token,
          userInfo: {
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
          },
        },
      });
    } else {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SIGNUP_ERROR,
      payload: { message: data?.error },
    });
  }
}

function* login() {
  const userActions = yield select(selectors.userActions);
  let data;
  try {
    const result = yield call(request, "/login", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userActions),
    });
    ({ data } = result);
    if (data?.message) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: LOGIN_ERROR,
        payload: { message: data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: LOGIN_ERROR,
      payload: { message: data?.error },
    });
  }
}

function* logout() {
  try {
    yield call(request, "/logout", {
      method: "GET",
    });
    window.location.href = "/";
  } catch (error) {
    yield put({
      type: LOGOUT_ERROR,
      payload: "Unable to logout",
    });
  }
}

function* forgotPassword() {
  let userActions = yield select(selectors.userActions);
  let result;
  try {
    result = yield call(request, "/api/forgotpassword", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        email: userActions.email,
      }),
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (result?.data?.message) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: { message: result?.data?.message },
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* verifyToken(action) {
  const token = action.payload;
  let result;
  try {
    result = yield call(request, `/api/verifyToken/${token}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (result.data.success) {
      yield put({
        type: TOKEN_VERIFIED,
        payload: {
          email: result.data.email.toLowerCase(),
          message: "You're token has been verified",
        },
      });
    } else {
      yield put({
        type: TOKEN_ERROR,
        payload: {
          message:
            "There was an error verifying you're token, please try sending again",
        },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: TOKEN_ERROR,
      payload: result.message,
    });
  }
}

function* resetPassword(action) {
  let sendEmail = yield select(selectors.sendEmail);
  let userActions = yield select(selectors.userActions);
  let result;
  try {
    result = yield call(request, "/api/resetpassword", {
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify({
        email: sendEmail.email,
        password: userActions.password,
      }),
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (result.data.message) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: { message: result?.data?.message },
      });
    } else {
      yield put({
        type: RESET_PASSWORD_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* submitFile(action) {
  const userForm = yield select(selectors.userForm);
  const user = yield select(selectors.user);
  const formData = {
    ...userForm,
  };
  let fileUploads = Object.assign([], userForm?.files);
  let insuranceImageUploads = Object.assign([], userForm?.insurance_images);
  try {
    const createPdf = yield call(
      request,
      "/api/createpdf",
      {
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify(formData),
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json",
        },
      },
      "blob"
    );
    if (createPdf && !!createPdf.data) {
      let pdfBlob = new Blob([createPdf.data], { type: "application/pdf" });
      let today = yield formatDate();
      pdfBlob.name = `${formData.first_name}_${formData.last_name}_${today}_info.pdf`;
      fileUploads.unshift(pdfBlob);
      try {
        if (!userForm.id) {
          yield createDropboxFolder(formData);
        }
        yield uploadDropboxFile(formData, fileUploads);
        if (insuranceImageUploads) {
          yield uploadDropboxFile(
            formData,
            insuranceImageUploads,
            "insurance_"
          );
        }
      } catch (error) {
        console.log(error);
      }
      yield put({
        type: SUBMIT_DROPBOX_SUCCESS,
      });
      yield call(request, "/sendConfirmationEmail", {
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify({ user }),
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json",
        },
      });
    }
  } catch (error) {
    yield put({
      type: SUBMIT_DROPBOX_ERROR,
    });
    console.log(error);
  }
}

function* actionWatcher() {
  // User Action Items
  yield takeLeading(CHECK_TOKEN, checkToken);
  yield takeLeading(REFRESH_USER, refreshUser);
  yield takeLeading(GET_OPTIONS, getOptions);
  yield takeLeading(SIGNUP, signup);
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(FORGOT_PASSWORD, forgotPassword);
  yield takeLeading(TOKEN_VERIFY, verifyToken);
  yield takeLeading(RESET_PASSWORD, resetPassword);

  // Project Specific
  yield takeLeading(SUBMIT_DROPBOX, submitFile);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
