import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, Field, actions } from "react-redux-form";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import logo from "./img/logo.png";
import "./style.css";
import "../../css/globalStyle.css";
import Thankyou from "./thankyou";
import ModalBox from "../ModalBox";
import InputFields from "../InputFields";
import Instructions from "./pdf/instructions.pdf";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_CAPTCHA_KEY } from "../config";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      sportsArray: [],
      otherSport: "",
      disableSubmit: true,
      files: [],
      insuranceImages: [],
      uploadingFile: false,
      uploadingInsuranceImage: false,
    };
  }

  async handleSubmit() {
    const { dispatch } = this.props;
    dispatch({
      type: "SUBMIT_DROPBOX",
    });
  }

  changeAction(e) {
    const { userForm, dispatch } = this.props;
    const { id, name, value, checked, type } = e.target;
    let newSportsArray = Object.assign([], userForm?.sports);
    if (type === "checkbox" && checked) {
      if (newSportsArray.length === 0) {
        newSportsArray.push(id?.toUpperCase());
        dispatch(actions.change("userForm.sports", newSportsArray));
      } else {
        newSportsArray.push(id?.toUpperCase());
        this.setState({ [name]: newSportsArray });
        dispatch(actions.change("userForm.sports", newSportsArray));
      }
    } else if (type === "checkbox" && !checked) {
      newSportsArray =
        newSportsArray?.length &&
        newSportsArray?.filter((x) => x !== id?.toUpperCase());
      dispatch(actions.change("userForm.sports", newSportsArray));
      this.setState({ [name]: newSportsArray });
    } else {
      this.setState({ [name]: value });
    }
    if (id?.toUpperCase() === "OTHER SPORTS AND ACTIVITIES") {
      this.setState({
        otherSport: !this.state.otherSport,
      });
    }
  }

  addFiles(file) {
    let files = [...this.state.files];
    files.push(file);
    this.setState({ files });
    this.props.dispatch(actions.change("userForm.files", this.state.files));
  }

  removeFile(fileName) {
    const { userForm, dispatch } = this.props;
    let files = userForm?.files;
    files = files?.filter((x) => x.name !== fileName);
    this.setState({ files });
    dispatch(actions.change("userForm.files", files));
  }

  addInsuranceImage(file) {
    let insuranceImages = [...this.state.insuranceImages];
    insuranceImages.push(file);
    this.setState({ insuranceImages });
    this.props.dispatch(
      actions.change("userForm.insurance_images", this.state.insuranceImages)
    );
  }

  removeInsuranceImage(fileName) {
    const { userForm, dispatch } = this.props;
    let insuranceImages = userForm?.insurance_images;
    insuranceImages = insuranceImages?.filter((x) => x.name !== fileName);
    this.setState({ insuranceImages });
    dispatch(actions.change("userForm.insurance_images", insuranceImages));
  }

  render() {
    const { user, userActions, userForm, dispatch, options } = this.props;
    const {
      otherSport,
      disableSubmit,
      uploadingFile,
      uploadingInsuranceImage,
      sportsArray,
    } = this.state;

    // const sportsList = [
    //   "Basketball",
    //   "Football",
    //   "Soccer",
    //   "Baseball",
    //   "Swimming",
    //   "Track and Field",
    //   "Hockey",
    //   "Other(list)",
    // ];

    const sportsList = [];
    options?.data?.sports_options?.map((s) => sportsList?.push(s?.label));

    let knownSportsArray;
    if (userForm?.id) {
      knownSportsArray = userForm?.sports;
      // knownSportsArray =
      //   knownSportsArray?.length && knownSportsArray?.split(",");
    }

    if (userForm?.isLoading || user?.isLoading || userActions?.isLoading) {
      return (
        <div id="fullHeight">
          <div id="loading" />
        </div>
      );
    }

    const FileUploader = () => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.setState({ uploadingFile: false });
          this.addFiles(file);
        } else if (status === "removed") {
          this.removeFile(file?.name);
        } else if (status === "preparing") {
          this.setState({ uploadingFile: true });
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          // onDragOver={this.onDrop.bind(this)}
        />
      );
    };

    const InsuranceImageUploader = () => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.setState({ uploadingInsuranceImage: false });
          this.addInsuranceImage(file);
        } else if (status === "removed") {
          this.removeInsuranceImage(file?.name);
        } else if (status === "preparing") {
          this.setState({ uploadingInsuranceImage: true });
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/*"
          // onDragOver={this.onDrop.bind(this)}
        />
      );
    };

    if (userForm?.success) {
      // <Alert className="text-center hideDiv" variant="success">
      // 	Your information has successfully been submitted!
      // </Alert>
      return <Thankyou />;
    }

    return (
      <div className="formMain">
        <div className="formDiv">
          {!user?.login && <ModalBox />}
          {/* {dropbox &&
					dropbox.success && (
						<Alert className="text-center hideDiv" variant="success">
							Your information has successfully been submitted!
						</Alert>
					)} */}
          {userForm?.error && (
            <Alert className="text-center hideDiv avenirBold" variant="danger">
              Sorry, looks like there was an error submitting your information.
              Please try again.
            </Alert>
          )}
          <a
            href="#/"
            className="logoutLink avenirBold hoverDelay"
            onClick={() => dispatch({ type: "LOGOUT" })}
          >
            Logout
          </a>
          <img src={logo} className="logo" alt="Logo" />
          <h4 className="header avenirBold">
            Welcome to the Performance Medilytics RecoverSmart platform. Please
            enter in the following information, upload your files, and begin
            optimizing your recovery and performance today.
          </h4>
          <Form
            model="userForm"
            onSubmit={() => this.handleSubmit()}
            className="avenir"
          >
            <Row className="formRow">
              <Col lg={6}>
                <label>
                  First Name<span className="required">*</span>
                </label>
                <InputFields
                  type="text"
                  label="first name"
                  inputType="text"
                  classname="formInputs"
                  model=".first_name"
                  value={userForm?.first_name}
                  placeholder="First Name"
                  disabled={!!userForm?.first_name}
                />
              </Col>
              <Col lg={6}>
                <label>
                  Last Name<span className="required">*</span>
                </label>
                <InputFields
                  type="text"
                  label="last name"
                  inputType="text"
                  classname="formInputs"
                  model=".last_name"
                  value={userForm?.last_name}
                  placeholder="Last Name"
                  disabled={!!userForm?.last_name}
                />
              </Col>
              <Col lg={12}>
                <label>
                  Email<span className="required">*</span>
                </label>
                <InputFields
                  type="email"
                  label="email"
                  inputType="text"
                  classname="formInputs"
                  model=".email"
                  value={userForm?.email}
                  placeholder="Email"
                  disabled={!!userForm?.email}
                />
              </Col>
              <Col lg={12}>
                <label>
                  Date of Birth<span className="required">*</span>
                </label>
                <InputFields
                  type="date"
                  label="Date of Birth"
                  inputType="date"
                  classname="formInputs"
                  model=".dob"
                  value={userForm?.dob?.split("T")[0]}
                  placeholder="Date of birth"
                />
              </Col>
              <Col lg={12}>
                <label>
                  Address<span className="required">*</span>
                </label>
                <InputFields
                  type="text"
                  label="Address"
                  inputType="text"
                  classname="formInputs"
                  model=".address"
                  placeholder="Address"
                  value={userForm?.address}
                />
              </Col>
              <Col lg={6}>
                <label>
                  City<span className="required">*</span>
                </label>
                <InputFields
                  type="text"
                  label="City"
                  inputType="text"
                  classname="formInputs"
                  model=".city"
                  placeholder="City"
                  value={userForm?.city}
                />
              </Col>
              <Col lg={6}>
                <label>
                  State<span className="required">*</span>
                </label>
                <InputFields
                  type="select"
                  label="State"
                  defaultOption="State"
                  inputType="select"
                  classname="formInputs"
                  model=".state"
                  placeholder="State"
                  options={options?.data?.states}
                  value={userForm?.state}
                />
              </Col>
              <Col lg={12}>
                <label>
                  Zipcode<span className="required">*</span>
                </label>
                <InputFields
                  type="number"
                  label="Zipcode"
                  inputType="number"
                  classname="formInputs"
                  model=".zipcode"
                  placeholder="Zipcode"
                  value={userForm?.zipcode}
                />
              </Col>
              <Col lg={12}>
                <p className="dropzoneText">
                  <b>
                    Please upload insurance card images in the area below by
                    either dragging the relevant files or click "Browse" below
                    to select the folders. Please upload both the front and back
                    images of your license. Please reference the instructions
                    located{" "}
                    <a
                      href={Instructions}
                      className="hoverDelay"
                      title="Upload Instructions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{" "}
                    and also sent to you initially, for additional information.
                  </b>
                </p>
                {!uploadingInsuranceImage ? (
                  <InsuranceImageUploader />
                ) : (
                  <div id="loading" />
                )}
                <Row>
                  {userForm?.insurance_images?.map((file, i) => (
                    <Col key={i} lg={6}>
                      <div className="fileButtonDiv">
                        <p className="fileButtonName">{file?.name}</p>
                        <Button
                          className="fileCloseButton hoverDelay"
                          onClick={() => this.removeInsuranceImage(file?.name)}
                        >
                          X
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={12}>
                <p>
                  Sport or Activity Participated in (choose all that apply):
                </p>
                <div className="sportsDiv">
                  {sportsList?.map((type, i) => (
                    <div key={i} className="sportsCheckboxDiv">
                      <input
                        name="sportsArray"
                        label={type}
                        checked={
                          knownSportsArray?.includes(type?.toUpperCase()) ||
                          sportsArray?.includes(type?.toUpperCase())
                        }
                        type="checkbox"
                        id={type}
                        onChange={(e) => this.changeAction(e)}
                      />
                      <label className="sportsLabel">{type}</label>
                    </div>
                  ))}
                </div>
              </Col>
              {otherSport && (
                <Col lg={12}>
                  <label>Other Sports(s):</label>
                  <Control.text
                    className="formInputs"
                    model=".other_sports"
                    placeholder="Other Sports"
                  />
                </Col>
              )}
              <Col lg={12}>
                <label>
                  Current Symptoms (please be as detailed as possible)
                </label>
                <InputFields
                  type="textarea"
                  label="current symptoms"
                  inputType="textarea"
                  classname="formInputs formInputsTextArea"
                  model=".current_injury_symptoms"
                  rows="7"
                  placeholder="Enter current symptoms info here..."
                  ignore={true}
                />
              </Col>
              <Col lg={12}>
                <label>Current Treatment (if any)</label>
                <InputFields
                  type="textarea"
                  label="current Treatment (if any)"
                  inputType="textarea"
                  classname="formInputs formInputsTextArea"
                  model=".current_treatment"
                  rows="7"
                  placeholder="Enter current treatment info here..."
                  ignore={true}
                />
              </Col>
              <Col lg={12}>
                <label>Past (Related) Medical History</label>
                <InputFields
                  type="textarea"
                  label="past (related) medical history"
                  inputType="textarea"
                  classname="formInputs formInputsTextArea"
                  model=".past_injury_history"
                  rows="7"
                  placeholder="Enter past (related) medical history info here..."
                  ignore={true}
                />
              </Col>
              <Col lg={12}>
                <p>
                  Please scroll through and read the HIPAA form below, then
                  confirm at the bottom<span className="required">*</span>
                </p>
                <div className="consentFormDiv">
                  <h3>HIPAA Privacy Authorization Form</h3>
                  <p>
                    **Authorization for Use or Disclosure of Protected Health
                    Information
                    <br />
                    (Required by the Health Insurance Portability and
                    Accountability Act, 45 C.F.R. Parts 160 and 164)**
                  </p>
                  <ol>
                    <li>
                      Authorization. I authorize Performance Data Sciences, Inc.
                      to use and disclose the protected health information
                      described below.
                    </li>
                    <li>
                      Effective Period. This authorization for release of
                      information covers the period of healthcare for all past,
                      present and future periods.
                    </li>
                    <li>Extent of Authorization.</li>
                    <li>
                      I authorize the release of uploaded health information to
                      Performance Data Sciences, Inc.
                    </li>
                    <li>
                      This medical information may be used by Performance Data
                      Sciences, LLC for consultation or other purposes as I may
                      direct.
                    </li>
                    <li>
                      I understand that I have a right to revoke this
                      authorization, in writing, at any time. I understand that
                      a revocation is not effective to the extent that any
                      person or entity has already acted in reliance on my
                      authorization.
                    </li>
                    <li>
                      I understand that information used or disclosed pursuant
                      to this authorization may be disclosed by the recipient
                      and may no longer be protected by federal or state law.
                    </li>
                  </ol>
                  <div className="consentCheckboxDiv">
                    <Control.checkbox
                      model=".consentCheckbox"
                      className="consentCheckbox"
                      name="consentCheckbox"
                      // inline
                      type="checkbox"
                      id="consent"
                      required
                    />
                    <p className="consentCheckboxLabel">
                      <span className="required">*</span>I have carefully read
                      and understand the above information, and do herein
                      consent to its disclosure. I am aware that information
                      regarding my medical condition will be released to those
                      persons or agencies named above. I understand that, if the
                      person(s) or organization(s) that I authorize to receive
                      my protected health information are not subject to federal
                      and state health information privacy laws, subsequent
                      disclosure by such person(s) or organization(s) may not be
                      protected by those laws.
                    </p>
                  </div>

                  <label>
                    <b>
                      Enter Full Name for Signature
                      <span className="required">*</span>
                    </b>
                  </label>
                  <Control.text
                    model=".consentSignature"
                    className="formInputs"
                    name="consentSignature"
                    placeholder="Signature"
                    required
                  />
                </div>
              </Col>
              <Col lg={12}>
                <p className="dropzoneText">
                  <b>
                    Please upload files in the area below by either dragging the
                    relevant files or click "Browse" below to select the
                    folders. If you were provided more than one CD by your
                    medical professional, please select both sets of files prior
                    to hitting 'Submit' at the bottom. Please reference the
                    instructions located{" "}
                    <a
                      href={Instructions}
                      className="hoverDelay"
                      title="Upload Instructions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>{" "}
                    and also sent to you initially, for additional information.
                  </b>
                </p>
                {!uploadingFile ? <FileUploader /> : <div id="loading" />}
                <Row>
                  {userForm?.files?.map((file, i) => (
                    <Col key={i} lg={6}>
                      <div className="fileButtonDiv">
                        <p className="fileButtonName">{file?.name}</p>
                        <Button
                          className="fileCloseButton hoverDelay"
                          onClick={() => this.removeFile(file?.name)}
                        >
                          X
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col lg={12}>
                {REACT_APP_CAPTCHA_KEY && (
                  <React.Fragment>
                    <ReCAPTCHA
                      sitekey={REACT_APP_CAPTCHA_KEY}
                      onChange={() => this.setState({ disableSubmit: false })}
                    />
                    <br />
                  </React.Fragment>
                )}
                <Button
                  className="submitButton hoverDelay"
                  disabled={
                    disableSubmit || uploadingFile || uploadingInsuranceImage
                  }
                  type="submit"
                >
                  {!userForm?.id ? "Submit" : "Update"}
                </Button>
              </Col>
            </Row>
          </Form>
          {/* </form> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  user: state.user,
  options: state.options,
  userActions: state.userActions,
  userForm: state.userForm,
  dropbox: state.dropbox,
});

export default connect(mapStateToProps)(Home);
