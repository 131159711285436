import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, Field, actions, Errors } from "react-redux-form";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import AlertMessage from "../AlertMessage";
import "./style.css";
import "../../css/globalStyle.css";
import InputFields from "../InputFields";

class ModalBox extends Component {
  state = {
    modalShow: true,
    setModalShow: false,
    register: this.props?.userActions?.signupTried || false,
    login:
      this.props?.userActions?.loginTried ||
      this.props?.sendEmail?.reset_success ||
      false,
    forgot: false,
    model: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    let url = window.location.href;
    url = new URL(url);
    const token = url.searchParams.get("token");
    if (token) {
      dispatch({ type: "TOKEN_VERIFY", payload: token });
    }
  }

  async handleSubmit() {
    console.log("Submit");
  }

  render() {
    const { userActions, dispatch, options, sendEmail } = this.props;
    const { modalShow, setModalShow, register, login, forgot } = this.state;

    const passwordMatch =
      (userActions?.password?.length >= 6 &&
        userActions?.confirm_password?.length >= 6 &&
        userActions?.password === userActions?.confirm_password) ||
      userActions?.password?.length < 6 ||
      userActions?.confirm_password?.length < 6 ||
      (!userActions?.password && !userActions?.confirm_password);

    return (
      <div className="formMain">
        <div className="formDiv">
          <Modal
            className="mainModal"
            show={modalShow}
            onHide={() => {
              return;
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modal.Header closeButton> */}
            <Modal.Header>
              <AlertMessage />
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="modalHeader avenirBoldItalic"
              >
                {register
                  ? "Sign Up"
                  : !sendEmail?.token_success
                  ? "Login"
                  : "Reset Password"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form model="userActions" onSubmit={() => this.handleSubmit()}>
                {/* <h4>Centered Modal</h4> */}
                {((!login &&
                  !forgot &&
                  !sendEmail?.token_success &&
                  !sendEmail?.reset_success) ||
                  register) && (
                  <Row className="avenir">
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Email<span className="required">*</span>
                      </label>
                      <InputFields
                        type="email"
                        label="email"
                        inputType="text"
                        classname="formInputs"
                        model=".email"
                        placeholder="Email"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <label>
                        First Name<span className="required">*</span>
                      </label>
                      <InputFields
                        type="text"
                        label="first name"
                        inputType="text"
                        classname="formInputs"
                        model=".first_name"
                        placeholder="First Name"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <label>
                        Last Name<span className="required">*</span>
                      </label>
                      <InputFields
                        type="text"
                        label="last name"
                        inputType="text"
                        classname="formInputs"
                        model=".last_name"
                        placeholder="Last Name"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <label>
                        Password<span className="required">*</span>
                      </label>
                      <InputFields
                        type="password"
                        label="password"
                        inputType="text"
                        classname="formInputs"
                        model=".password"
                        placeholder="Password"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <label>
                        Confirm Password<span className="required">*</span>
                      </label>
                      <InputFields
                        type="password"
                        label="confirm password"
                        inputType="text"
                        classname="formInputs"
                        model=".confirm_password"
                        placeholder="Confirm Password"
                      />
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Where did you hear about us?
                        <span className="required">*</span>
                      </label>
                      <InputFields
                        label="Referral"
                        type="select"
                        inputType="select"
                        classname="formInputs"
                        model=".referral"
                        options={options?.data?.referral_sources}
                        defaultOption="Select an option"
                      />
                    </Col>
                    {userActions?.referral?.toUpperCase() === "OTHER" && (
                      <Col lg={12} className="modalInputCol">
                        <InputFields
                          type="text"
                          label="other referral"
                          inputType="text"
                          classname="formInputs"
                          model=".other_referral"
                          placeholder="Other Referral"
                        />
                      </Col>
                    )}
                    <Col lg={12} className="modalInputCol">
                      <a
                        href="#/"
                        className="registerToggleLink hoverDelay"
                        onClick={() => {
                          this.setState({
                            register: false,
                            login: true,
                            forgot: false,
                          });
                          dispatch(actions.change("userActions", {}));
                          dispatch(actions.change("userForm", { files: [] }));
                          dispatch(actions.change("signup", {}));
                          dispatch(actions.change("sendEmail", {}));
                        }}
                      >
                        Already have an account?
                      </a>
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <Control.button
                        model="userActions"
                        className="registerButton hoverDelay avenirBold"
                        disabled={{ valid: false } || !passwordMatch}
                        type="submit"
                        onClick={() => dispatch({ type: "SIGNUP" })}
                      >
                        Signup
                      </Control.button>
                      {!passwordMatch && (
                        <p className="errorText">Your passwords do not match</p>
                      )}
                    </Col>
                  </Row>
                )}
                {(login || sendEmail?.reset_success) && (
                  <Row className="avenir">
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Email<span className="required">*</span>
                      </label>
                      <InputFields
                        type="email"
                        label="email"
                        inputType="text"
                        classname="formInputs"
                        model=".email"
                        placeholder="Email"
                      />
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Password<span className="required">*</span>
                      </label>
                      <InputFields
                        type="password"
                        label="password"
                        inputType="text"
                        classname="formInputs"
                        model=".password"
                        placeholder="Password"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <a
                        href="#/"
                        className="registerToggleLink hoverDelay"
                        onClick={() => {
                          this.setState({
                            register: true,
                            login: false,
                            forgot: false,
                          });
                          dispatch(actions.change("userActions", {}));
                          dispatch(actions.change("userForm", { files: [] }));
                          dispatch(actions.change("user", { files: [] }));
                        }}
                      >
                        Don't have an account?
                      </a>
                    </Col>
                    <Col lg={6} className="modalInputCol modalForgotPwdCol">
                      <a
                        href="#/"
                        className="registerToggleLink hoverDelay"
                        onClick={() => {
                          this.setState({
                            register: false,
                            login: false,
                            forgot: true,
                          });
                          dispatch(actions.change("userActions", {}));
                          dispatch(actions.change("userForm", { files: [] }));
                        }}
                      >
                        Forgot Password
                      </a>
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <Control.button
                        model="userActions"
                        className="registerButton hoverDelay avenirBold"
                        disabled={{ valid: false }}
                        type="submit"
                        onClick={() => dispatch({ type: "LOGIN" })}
                      >
                        Login
                      </Control.button>
                    </Col>
                  </Row>
                )}
                {forgot && (
                  <Row className="avenir">
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Email<span className="required">*</span>
                      </label>
                      <InputFields
                        type="email"
                        label="email"
                        inputType="text"
                        classname="formInputs"
                        model=".email"
                        placeholder="Email"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <a
                        href="#/"
                        className="registerToggleLink hoverDelay"
                        onClick={() => {
                          this.setState({
                            register: false,
                            login: true,
                            forgot: false,
                          });
                          dispatch(actions.change("userActions", {}));
                          dispatch(actions.change("userForm", { files: [] }));
                        }}
                      >
                        Back to sign in
                      </a>
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <Control.button
                        model="userActions"
                        className="registerButton hoverDelay avenirBold"
                        disabled={{ valid: false }}
                        type="submit"
                        onClick={() => dispatch({ type: "FORGOT_PASSWORD" })}
                      >
                        Send Token
                      </Control.button>
                    </Col>
                  </Row>
                )}
                {!login && sendEmail?.token_success && (
                  <Row className="avenir">
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Password<span className="required">*</span>
                      </label>
                      <InputFields
                        type="password"
                        label="password"
                        inputType="text"
                        classname="formInputs"
                        model=".password"
                        placeholder="Password"
                      />
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <label>
                        Confirm Password<span className="required">*</span>
                      </label>
                      <InputFields
                        type="password"
                        label="confirm password"
                        inputType="text"
                        classname="formInputs"
                        model=".confirm_password"
                        placeholder="Confirm Password"
                      />
                    </Col>
                    <Col lg={6} className="modalInputCol">
                      <a href="/" className="registerToggleLink hoverDelay">
                        Back to sign in
                      </a>
                    </Col>
                    <Col lg={12} className="modalInputCol">
                      <Control.button
                        model="userActions"
                        className="registerButton hoverDelay avenirBold"
                        disabled={{ valid: false } || !passwordMatch}
                        type="submit"
                        onClick={() => dispatch({ type: "RESET_PASSWORD" })}
                      >
                        Reset Password
                      </Control.button>
                      {!passwordMatch && (
                        <p className="errorText">Your passwords do not match</p>
                      )}
                    </Col>
                  </Row>
                )}
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  userForm: state.userForm,
  sendEmail: state.sendEmail,
});

export default connect(mapStateToProps)(ModalBox);
