export function formatDate(dateOnly = false, date = null, source = null) {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today;
  if (date) {
    today = new Date(date);
  } else {
    today = new Date();
  }
  if (source === "matchup") {
    const dateParam = today;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    // console.log("injuryYear: " + injuryYear);
    // console.log("currentYear: " + currentYear);
    // if (((currentYear - injuryYear) > 1 || (currentYear - injuryYear) < -1)) {
    //   return "N/A";
    // }
    const months = monthDifference(dateParam, currentDate);
    if (months > 12) {
      return "N/A";
    }
  }

  let dd = today.getDate();
  let mm = today.getMonth();
  let hh = today.getHours();
  if (date) {
    if (dd < 10) {
      dd = `0${dd}`;
    }
    mm += 1;

    if (mm < 10) {
      mm = `0${mm}`;
    }

    const yyyy = today.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  }
  let h = hh;
  if (h > 12) {
    h = hh - 12;
    // dd = "PM";
  }
  if (h === 0) {
    h = 12;
  }
  let minutes = today.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  mm = months[mm];
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm += 1;
    mm = `0${mm}`;
  }
  if (dateOnly === true) {
    return (today = `${mm} ${dd}, ${yyyy}`);
  } else {
    return (today = `${mm} ${dd}, ${yyyy} ${h}:${minutes}${
      hh >= 12 ? " PM" : " AM"
    }`);
  }
}

function monthDifference(dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7 * 4;
  return Math.abs(Math.round(diff));
}

export function formatTime(time) {
  let hour = time.slice(0, 2);
  let minute = time.slice(3, 6);
  let timeFormat;
  if (hour >= 12) {
    timeFormat = "PM";
    hour = hour - 12;
    if (hour === 0) {
      hour = 12;
    }
  } else {
    timeFormat = "AM";
  }
  // hour = hour - 5;
  if (hour < 0) {
    timeFormat = "PM";
    hour += 12;
  }
  return hour + ":" + minute + " " + timeFormat;
}

export function calculateHeight(height) {
  let feet = Math.floor(height / 12);
  let inches = Math.floor(height % 12);
  return `${feet}' ${inches}"`;
}

/**
 *  Parses the JSON returned by a network request
 *
 * @param {object} response A response from a network request
 */
function parseJSON(response) {
  return response.json();
}

function parseBlob(response) {
  return response.blob();
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "error"
 */
export function request(url, options, type) {
  return fetch(url, options)
    .then(checkStatus)
    .then(type === "blob" ? parseBlob : parseJSON)
    .then((data) => ({
      data,
    }))
    .catch((error) => ({
      error,
    }));
}
