// Check Token
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";

// Submit Dropbox
export const SUBMIT_DROPBOX = "SUBMIT_DROPBOX";
export const SUBMIT_DROPBOX_SUCCESS = "SUBMIT_DROPBOX_SUCCESS";
export const SUBMIT_DROPBOX_ERROR = "SUBMIT_DROPBOX_ERROR";

// Options
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_ERROR = "GET_OPTIONS_ERROR";

// User Actions
export const REFRESH_USER = "REFRESH_USER";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const TOKEN_VERIFY = "TOKEN_VERIFY";
export const TOKEN_VERIFIED = "TOKEN_VERIFIED";
export const TOKEN_ERROR = "TOKEN_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const submitToDropbox = (payload) => ({
  type: SUBMIT_DROPBOX,
  payload,
});

export default submitToDropbox;
