// # Development
// export const REACT_APP_DROPBOX_KEY =
//   "H63uLrYnbnMAAAAAAACoRxNiQTqmWjPJucxKmVCzOQEnDnbEqxA9b4gLTjgKIkhb";
// export const REACT_APP_DROPBOX_NAME = "Performance Medilytics Upload";

// # Production
export const REACT_APP_DROPBOX_KEY =
  "e9K_wZCdYlAAAAAAAAAR-JfhyRNxVqIWO-sznPtXfKAJczVy0aWsaqZqQwwwhxrS";
export const REACT_APP_DROPBOX_NAME = "Performance Medilytics Upload";

// Captcha Key
export const REACT_APP_CAPTCHA_KEY = "6LdE9LEZAAAAAOWONNko3B0P-rYe70IRHQ54nrEb";
