import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Errors, actions } from "react-redux-form";

class InputFields extends Component {
  displayOptions(options) {
    const { defaultOption } = this.props;
    const optionArr = [
      <option key={0} value="">
        {defaultOption}
      </option>,
    ];
    options?.map((data, i) => {
      return optionArr.push(
        <option key={++i} value={data.value}>
          {data.label}
        </option>
      );
    });
    return optionArr;
  }

  normalizeDob = (val, prevVal) => {
    // Prevent non-digit characters being entered
    if (isNaN(parseInt(val[val.length - 1], 10))) {
      return val.slice(0, -1);
    }

    // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
    if (prevVal && prevVal.length >= val.length) {
      return val;
    }

    // Add / at appropriate sections of the input
    if (val.length === 2 || val.length === 5) {
      val += "/";
    }

    // Prevent characters being entered after Dob is full
    if (val.length >= 10) {
      return val.slice(0, 10);
    }

    return val;
  };

  render() {
    const {
      model,
      label,
      type,
      value,
      disabled,
      classname,
      inputType,
      options,
      ignore,
      placeholder,
      rows,
    } = this.props;
    const email = (value) =>
      value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

    return (
      <React.Fragment>
        {inputType === "text" && (
          <Control.text
            className={classname}
            model={model}
            type={type}
            value={value}
            placeholder={placeholder}
            validators={{
              required: (val) =>
                ignore || type === "password" ? true : val?.length,
              isValidEmail: (val) =>
                type !== "email" ? true : !val?.length ? true : email(val),
              isValidPassword: (val) =>
                type !== "password" ? true : val?.length >= 6,
            }}
            disabled={disabled}
          />
        )}
        {inputType === "number" && (
          <Control.text
            className={classname}
            model={model}
            type={type}
            value={value}
            placeholder={placeholder}
            min={0}
            validators={{
              required: (val) =>
                ignore || type === "password" ? true : val?.length,
            }}
            disabled={disabled}
          />
        )}
        {inputType === "select" && (
          <Control.select
            className={classname}
            model={model}
            type={type}
            validators={{
              required: (val) => (ignore ? true : val?.length),
            }}
            disabled={disabled}
          >
            {this.displayOptions(options)}
          </Control.select>
        )}
        {inputType === "textarea" && (
          <React.Fragment>
            <Control.textarea
              className={classname}
              model={model}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              rows={rows}
              validators={{
                required: (val) => (ignore ? true : val?.length),
              }}
            />
          </React.Fragment>
        )}
        {inputType === "date" && (
          <React.Fragment>
            <Control.text
              className={classname}
              model={model}
              type={type}
              normalize={this.normalizeDob}
              validators={{
                required: (val) => val?.length,
              }}
              value={value}
              disabled={disabled}
            />
          </React.Fragment>
        )}
        <Errors
          style={{ fontWeight: "bold", color: "red" }}
          className="formInputError avenirBoldItalic"
          model={model}
          messages={{
            required: `Please provide a ${label}`,
            isValidEmail: "Please enter a valid email",
            isValidPassword:
              "Your password is too short, must have at least 6 characters",
          }}
          show={{ touched: true, focus: false }}
        />
      </React.Fragment>
    );
  }
}

export default connect()(InputFields);
