"use strict";

import {
  REACT_APP_DROPBOX_NAME,
  REACT_APP_DROPBOX_KEY,
} from "../components/config";
require("dotenv").config();
var Dropbox = require("dropbox").Dropbox;
var dbx = new Dropbox({ accessToken: REACT_APP_DROPBOX_KEY });

export async function formatDate() {
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const d = new Date();
  const date = d.getDate();
  let month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
  month = months[month - 1];
  const year = d.getFullYear();

  return month + "_" + date + "_" + year;
}

export async function createDropboxFolder(user) {
  const dropboxName = REACT_APP_DROPBOX_NAME;
  const today = await formatDate();
  const userDirectory = `/${dropboxName}/${user.first_name.toUpperCase()}_${user.last_name.toUpperCase()}_${
    user.email.split("@")[0]
  }`;
  // dbx
  // 	.filesListFolder({ path: '' })
  // 	.then(function(response) {
  // 		console.log('response', response);
  // 		// displayFiles(response.entries);
  // 		console.log(response);
  // 	})
  // 	.catch(function(error) {
  // 		console.error(error);
  // 	});

  return dbx
    .filesCreateFolderV2({ path: userDirectory })
    .then(function (response) {
      // console.log(response);
      return;
    })
    .catch(function (error) {
      console.log(error);
      return;
    });
}

export async function uploadDropboxFile(user, fileUploads, type) {
  const dropboxName = REACT_APP_DROPBOX_NAME;
  const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
  const files = fileUploads;
  const subFolder = type ? `insurance/${type}` : "";

  try {
    const today = await formatDate();
    for (let i = 0; i < files.length; i++) {
      if (files[i].size < UPLOAD_FILE_SIZE_LIMIT) {
        // File is smaller than 150 Mb - use filesUpload API
        dbx
          .filesUpload({
            path:
              `/${dropboxName}/${user.first_name.toUpperCase()}_${user.last_name.toUpperCase()}_${
                user.email.split("@")[0]
              }/` +
              subFolder +
              files[i].name,
            contents: files[i],
          })
          .then(function (response) {
            // var results = document.getElementById("results");
            // results.appendChild(document.createTextNode('File uploaded!'));
            // console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        // File is bigger than 150 Mb - use filesUploadSession* API
        const maxBlob = 24 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size

        var workItems = [];

        var offset = 0;

        while (offset < files[i].size) {
          var chunkSize = Math.min(maxBlob, files[i].size - offset);
          workItems.push(files[i].slice(offset, offset + chunkSize));
          offset += chunkSize;
        }

        const task = workItems.reduce((acc, blob, idx, items) => {
          if (idx == 0) {
            // Starting multipart upload of file
            return acc.then(function () {
              return dbx
                .filesUploadSessionStart({ close: false, contents: blob })
                .then((response) => response.session_id);
            });
          } else if (idx < items.length - 1) {
            // Append part to the upload session
            return acc.then(function (sessionId) {
              var cursor = { session_id: sessionId, offset: idx * maxBlob };
              return dbx
                .filesUploadSessionAppendV2({
                  cursor: cursor,
                  close: false,
                  contents: blob,
                })
                .then(() => sessionId);
            });
          } else {
            // Last chunk of data, close session
            return acc.then(function (sessionId) {
              var cursor = {
                session_id: sessionId,
                offset: files[i].size - blob.size,
              };
              var commit = {
                path:
                  `/${dropboxName}/${user.first_name.toUpperCase()}_${user.last_name.toUpperCase()}_${
                    user.email.split("@")[0]
                  }/` + files[i].name,
                mode: "add",
                autorename: true,
                mute: false,
              };
              return dbx.filesUploadSessionFinish({
                cursor: cursor,
                commit: commit,
                contents: blob,
              });
            });
          }
        }, Promise.resolve());

        task
          .then(function (result) {
            // var results = document.getElementById('results');
            // results.appendChild(document.createTextNode('File uploaded!'));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  } catch (error) {
    // console.log(error);
  }
  return false;
}
