import React, { Component } from "react";
import Home from "./components/Home/index";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Loading from "./components/Loading";
import AlertMessage from "./components/AlertMessage";

class App extends Component {
  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch({ type: "GET_OPTIONS" });
    if (user?.login) {
      dispatch({ type: "REFRESH_USER" });
    }
  }
  render() {
    const { options } = this.props;
    if (!options || options?.isLoading) {
      return <Loading />;
    }

    return (
      <div>
        {/* <AlertMessage /> */}
        <Route exact path="/" component={Home} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  signup: state.signup,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(App);
